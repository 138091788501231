import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { Button } from "antd";
import constants from "../../api/constants";

const uri = constants.API_URL;

export default function StripePortalLink({
  buttonTitle,
  user,
  contract,
  title,
}) {
  const contractId = contract?._id;
  const { pathname } = useLocation();

  return (
    <div>
      <form method="POST" action={uri + "/stripe/create-portal-session"}>
        <input type="hidden" name="contractId" value={contractId} />

        <input type="hidden" name="pathname" value={pathname} />
        {/* <input type="hidden" name="email" value={user?.email} /> */}
        <Button type="primary" className="ant-btn" htmlType="submit">
          {buttonTitle || "Open the billing portal"}
        </Button>
      </form>
    </div>
  );
}

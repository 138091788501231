import * as React from "react";

import { Typography, Tabs, Card, Descriptions, Badge } from "antd";

import moment from "moment";

import _ from "lodash";

export default function Certificates({ contract }) {
  const items = _.chain(contract)
    .get("certificates")
    .map((certificate, i) => {
      return {
        key: certificate._id,
        label: "Certificate #" + (i + 1),
        children: <Certificate certificate={certificate} />,
      };
    })
    .value();

  return (
    <>
      <div>
        This section contains data about your Eurocontrol NM B2B certificates.
      </div>
      <br />
      <Tabs
        defaultActiveKey="1"
        items={items}
        tabBarStyle={{ fontWeight: 600 }}
      />
    </>
  );
}

function Certificate({ certificate }) {
  const items = [
    {
      key: "1",
      label: "Name",
      children: certificate.name,
      span: 4,
    },
    {
      key: "2",
      label: "Starting",
      children: moment.utc(certificate.starting).format("YYYY-MM-DD"),
      span: 2,
    },
    {
      key: "3",
      label: "Expiry",
      children: moment.utc(certificate.expiry).format("YYYY-MM-DD"),
      span: 2,
    },
    {
      key: "6",
      label: "Status",
      children: certificate.isActive ? (
        <Badge status="success" text="Active" />
      ) : (
        <Badge status="error" text="Not active" />
      ),
      span: 4,
    },
    {
      key: "7",
      label: "Data type",
      children:
        certificate.flavour === "OPS"
          ? "Operational data (OPS)"
          : "Test data (PREOPS)",
      span: 4,
    },
    {
      key: "8",
      label: "Organisation",
      children: certificate?.metadata?.organizationalUnitName,
      span: 4,
    },
    {
      key: "9",
      label: "Renewal",
      children: (
        <>
          ⚠️ Renew this certificate before the{" "}
          {moment
            .utc(certificate.expiry)
            .subtract(3, "months")
            .format("Do of MMMM YYYY")}
        </>
      ),
      span: 4,
    },
  ];
  return <Descriptions bordered items={items} />;
}

import * as React from "react";

import { Input, Table } from "antd";

import _ from "lodash";
import RemoveUserButton from "./RemoveUserButton";
import moment from "moment";

export default function ActiveUsers({ contract }) {
  let users = _.get(contract, "users", []);

  const [search, setSearch] = React.useState("");

  users =
    search.length === 0
      ? users
      : _.filter(users, (user) => _.includes(user.email, search));

  users = _.orderBy(users, "email");

  const columns = [
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Added on",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (ele) => moment.utc(ele).format("YYYY-MM-DD HH:mm z")
    },
    {
      title: "Actions",
      key: "actions",
      render: (ele) => <RemoveUserButton user={ele} contract={contract} />,
    },
  ];

  return (
    <>
      <Input.Search
        placeholder="Search for active users"
        onChange={(ev) => setSearch(ev.currentTarget.value)}
      />
      <br />
      <br />
      <Table
        dataSource={users}
        columns={columns}
        pagination={true}
        rowKey={"email"}
        size="small"
      />
    </>
  );
}

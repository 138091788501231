import * as React from "react";

import {
  Typography,
  Tabs,
  Card,
  Descriptions,
  Badge,
  Button,
  Table,
} from "antd";

import moment from "moment";

import _ from "lodash";
import { useParams } from "react-router-dom";
import {
  contractCreateApiKeyGQL,
  contractDeleteApiKeyGQL,
} from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCopy, faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import DeveloperWebhooks from "./DeveloperWebhooks";
import DeveloperAPIKeys from "./DeveloperAPIKeys";
import DeveloperUsersAPI from "./DeveloperUsersAPI";
import DeveloperZapier from "./DeveloperZapier";
import DeveloperFlightsAPI from "./DeveloperFlightsAPI";

export default function Developer({ contract }) {
  const items = [
    {
      key: "1",
      label: "API keys",
      children: <DeveloperAPIKeys contract={contract} />,
    },
    {
      key: "2",
      label: "Users API",
      children: <DeveloperUsersAPI contract={contract} />,
    },
    {
      key: "3",
      label: "Flights API",
      children: <DeveloperFlightsAPI contract={contract} />,
    },
    {
      key: "4",
      label: "Webhooks",
      children: <DeveloperWebhooks contract={contract} />,
    },
    {
      key: "5",
      label: "Zapier",
      children: <DeveloperZapier contract={contract} />,
    },
  ];

  return (
    <>
      <div>
        You can use our developper tools for:
        <ul>
          <li>
            <strong>User management: </strong>use our <strong>Users API</strong>{" "}
            to automatically add/remove users to your user list. This is usefull
            to automate your user list.
          </li>
          <li>
            <strong>Connect your IT softwares with CTOT data</strong>: brings
            your data integration to the next level with{" "}
            <strong>Flights API</strong>.
            <br />
            Build custom applications for complex use-case. Example: create
            custom email notification for flight data changes, integrate CTOT
            data into your internal softwares, etc.
          </li>
          <li>
            <strong>Zapier:</strong> create automations based on flight data and
            notifications, no coding required, setup in less than 5 minutes for
            a non-developer.
          </li>
        </ul>
        <br />
      </div>
      <br />
      <Tabs
        defaultActiveKey="1"
        items={items}
        tabBarStyle={{ fontWeight: 600 }}
      />
    </>
  );
}

const { Title } = Typography;

const columns = [
  {
    title: "Created at",
    dataIndex: ["createdAt"],
    render: (ele) => ele && moment.utc(ele).format("YYYY-MM-DD HH:mm"),
    key: "createdAt",
    order: 1,
  },
  {
    title: "Key",
    dataIndex: ["key"],
    render: (ele) => <DisplayKey apiKey={ele} />,
    key: "key",
    order: 2,
  },
  {
    title: "Delete",
    render: (ele) => <OneKeyActions apiKey={ele} />,
  },
];

function Api({ contract }) {
  let [requestApiKey, { loading }] = useMutation(contractCreateApiKeyGQL, {
    variables: { contractId: contract._id },
    refetchQueries: ["user"],
  });

  const apiKeys = _.get(contract, "keys", []);

  return (
    <div className="">
      <Title level={3}>Api Keys</Title>
      <p>
        You can manage your Api Keys here. This can be usefull if you wish to
        automate your user managementvia the User Api or access our Fligth Api.
        <br />
      </p>

      <Button
        type="primary"
        className="ant-btn"
        onClick={requestApiKey}
        loading={loading}
      >
        Create a new Api Key
      </Button>
      <br />
      <br />
      <Table dataSource={apiKeys} columns={columns} pagination={false} />
    </div>
  );
}

function OneKeyActions(props) {
  let params = useParams();

  const apiKey = props.apiKey;
  const contractId = params.contractId;

  let [deleteApiKey, { loading }] = useMutation(contractDeleteApiKeyGQL, {
    variables: { contractId, key: apiKey.key },
    refetchQueries: ["user"],
  });
  return (
    <div className="">
      <Button
        icon={
          <FontAwesomeIcon
            icon={faTrash}
            onClick={deleteApiKey}
            style={{ cursor: "pointer", fontSize: 18 }}
          />
        }
        loading={loading}
      />

      {/*  <button type="submit" className="ant-btn" onClick={deleteApiKey}>
        Delete Api Key
      </button> */}
    </div>
  );
}

function DisplayKey(props) {
  const apiKey = props.apiKey;
  const hiddenApiKey =
    (apiKey &&
      "*".repeat(Math.max(apiKey.length, 10) - 10) +
        apiKey.substring(apiKey.length - 10, apiKey.length)) ||
    "You don't have an Api Key. Please create one.";

  const [color, setColor] = React.useState("#fff");
  const [hidden, setHidden] = React.useState(true);

  const onClickDisplay = () => {
    setHidden(!hidden);
  };

  const onClickColor = () => {
    setColor("#5F5");
    setTimeout(() => setColor("white"), 500);
  };

  return (
    <div className="">
      <div style={{ fontFamily: "monospace", overflowWrap: "anywhere" }}>
        {hidden ? hiddenApiKey : apiKey}{" "}
      </div>
      <FontAwesomeIcon
        icon={hidden ? faEye : faEyeSlash}
        onClick={() => {
          onClickDisplay();
        }}
        style={{ cursor: "pointer", fontSize: 18 }}
      />
      &nbsp;
      <FontAwesomeIcon
        icon={faCopy}
        onClick={() => {
          onClickColor();
          navigator.clipboard.writeText(apiKey);
        }}
        style={{ color, cursor: "pointer", fontSize: 18 }}
      />
    </div>
  );
}

import * as React from "react";

import { Typography, Tabs, Descriptions, Table } from "antd";

import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

export default function Organisation({ contract }) {
  const items = [
    {
      key: "1",
      label: "Details",
      children: <Details contract={contract} />,
    },
    {
      key: "2",
      label: "Admin access",
      children: <AdminAcesss contract={contract} />,
    },
    {
      key: "3",
      label: "Notified users",
      children: <NotifiedUsers contract={contract} />,
    },
  ];

  return (
    <>
      <div>
        This section contains your basic organisation details.
        <br />
        <br />
      </div>
      <br />
      <Tabs
        defaultActiveKey="1"
        items={items}
        tabBarStyle={{ fontWeight: 600 }}
      />
    </>
  );
}

function Details({ contract }) {
  const items = [
    {
      key: "1",
      label: "Name",
      children: contract.displayName,
      span: 4,
    },
  ];
  return <Descriptions bordered items={items} />;
}

function AdminAcesss({ contract }) {
  const admins = _.get(contract, "admins", []);

  const items = [
    {
      key: "1",
      title: "Email",
    },
  ];

  return (
    <>
      This list contains the list of users having access to this administration
      console.
      <br />
      <br />
      <Table
        bordered
        columns={items}
        dataSource={admins}
        title={() => (
          <>
            <FontAwesomeIcon icon={faWarning} /> Please send us an email from an
            administrator's email if you wish to update your administrator's
            list.
          </>
        )}
      />
    </>
  );
}

function NotifiedUsers({ contract }) {
  const admins = _.get(contract, "efbAdmins", []);

  const items = [
    {
      key: "1",
      title: "Email",
    },
  ];

  return (
    <>
      This list contains the list of users notified of app updates, significant
      changes in the functions of CTOT app. It can be used by your IT services
      to track the app lifecycle for example.
      <br />
      <br />
      <Table
        bordered
        columns={items}
        dataSource={admins}
        title={() => (
          <>
            <FontAwesomeIcon icon={faWarning} /> Please send us an email from an
            administrator's email if you wish to update this list.
          </>
        )}
      />
    </>
  );
}

import * as React from "react";

import { Typography, Tabs, Descriptions, Table } from "antd";

import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import constants from "../../api/constants";

export default function Subscription({ contract }) {
  const items = [
    {
      key: "1",
      label: "Pricing",
      children: <Pricing contract={contract} />,
    },
    {
      key: "2",
      label: "Usage",
      children: "",
    },
  ];

  return (
    <>
      <div>
        This section contains your subscription details.
        <br />
        <br />
      </div>
      <br />
      <Tabs
        defaultActiveKey="1"
        items={items}
        tabBarStyle={{ fontWeight: 600 }}
      />
    </>
  );
}

function Pricing({ contract }) {
  const subscriptions = _.get(contract, "stripe.subscriptions", []);

  return subscriptions.map((subscription, i) => (
    <Price priceId={subscription?.plan?.id} />
  ));
}

function Price({ priceId }) {
  const [price, setPrice] = React.useState();

  const getData = async () => {
    const res = await fetch(
      constants.API_URL + "/stripe/get-price/" + priceId,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (res.status === 200) setPrice((await res.json())?.price);
  };

  React.useEffect(() => {
    if (priceId) getData();
  }, [priceId]);

  console.log(price);

  return <>{price?.id}</>;
}

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
} from "@apollo/client";

import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import DebounceLink from "apollo-link-debounce";

import { Button, notification } from "antd";
import constants from "../api/constants";

import _ from "lodash";
import { globalNavigate } from "../api/browserHistory.js";

let uri = constants.API_URL + "/graphql";

const httpLink = createHttpLink({
  uri,
  credentials: "include",
});

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const ErrorLink = onError((error) => {
  try {
    let { graphQLErrors, networkError, response, operation } = error;

    if (graphQLErrors) {
      //console.log("One error received", operation, response);

      _.uniqBy(graphQLErrors, (err) => err.extensions.message).map(
        ({ message, locations, path, extensions }) => {
          // Know errors
          // 4031: Not logged in
          // 4032: No subscriptions
          // 4000: Generic error with popup

          const code = _.get(extensions, "code");
          const extensionMessage = _.get(extensions, "message");
          const extensionTitle = _.get(extensions, "title");
          const url = _.get(extensions, "url");
          const urlTitle = _.get(extensions, "urlTitle", "Read more");
          const navigateTo = _.get(extensions, "navigateTo");
          const navigateToTitle = _.get(extensions, "navigateToTitle", "Go");
          const ok = _.get(extensions, "ok", true);

          if (code === 4000) {
            let buttons = [];
            //if (ok) buttons.push({ text: "OK" });

            if (url)
              buttons.push({
                text: urlTitle,
                onPress: () => window.open(url, "_blank"),
              });
            if (navigateTo)
              buttons.push({
                text: navigateToTitle,
                onPress: () => globalNavigate(_.lowerCase(navigateTo)),
              });

            return notification.error({
              message: extensionTitle,
              description: (
                <>
                  {extensionMessage}
                  <div>
                    {buttons.map((ele, i) => (
                      <Button
                        key={i}
                        onClick={ele.onPress}
                        style={{ display: "block" }}
                      >
                        {ele.text}
                      </Button>
                    ))}
                  </div>
                </>
              ),
              duration: 0,
            });
          }

          if (message === "4031" || message === "4032") {
            //if (response) response.errors = null;

            /* return notification.error({
              message: "You need an active account",
              description:
                "Go to the subscription menu on the top right to subscribe, or subscribe on your iOS app.",
              duration: 10,
            }); */
            return;
          } else {
            console.warn(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
            return;
          }
        }
      );
    }

    if (networkError) {
      //Sentry.captureMessage("Network error " + networkError);
      //console.warn(`[Network error]: ${networkError}`);

      return;
    }
  } catch (e) {
    console.error("Error link", e);
    return;
  }
});

const retryLink = new RetryLink({});

export const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case "User":
        return `User:${responseObject.email}`;
      case "Person":
        return `Person:${responseObject.name}:${responseObject.email}`;
      case "ApiKey":
        return `ApiKey:${responseObject.key}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
  /* typePolicies: {
    FlightId: {
      fields: {
        flight: {
          // Equivalent to options.mergeObjects(existing, incoming).
          merge: true,
        },
      },
    },
  }, */
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    new DebounceLink(1000),
    cleanTypeName,
    retryLink,
    ErrorLink,
    httpLink,
  ]),
  cache,
});

export default apolloClient;

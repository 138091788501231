import * as React from "react";

import _ from "lodash";

export default function SignupStep1({ contract }) {
  return (
    <div>
      Welcome to the CTOT Account console.
      <br />
      <br /> We will setup your account and activate your access to CTOT app.
    </div>
  );
}

import * as React from "react";

import _ from "lodash";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

export default function DeveloperFlightsAPI({ contract }) {
  return (
    <>
      <div>
        Our <strong>Flights API</strong> enables you to fetch flight data using
        a simple REST endpoint. You can also create Webhooks or use Zapier to
        create automations. For example, you can use{" "}
        <strong>Flights API</strong> to integrate CTOT data directly into your
        EFB software.
        <br />
        <br />
        <strong>Flights API</strong> is <strong>a paid service</strong> and
        available upon request.
        <br />
        <br />
        <FontAwesomeIcon icon={faWarning} /> Your access to{" "}
        <strong>Flights API</strong> is currently disabled. Contact us at
        contact@ctot.app to activate your <strong>Flight API</strong>.
      </div>
      <br />
    </>
  );
}

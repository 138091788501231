import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons";

export default function HiddenText({ value }) {
  const [color, setColor] = React.useState("#fff");
  const [hidden, setHidden] = React.useState(true);

  const onClickDisplay = () => {
    setHidden(!hidden);
  };

  const onClickColor = () => {
    setColor("#5F5");
    setTimeout(() => setColor("white"), 500);
  };

  const hiddenValue =
    (value &&
      "*".repeat(Math.max(value.length, 10) - 10) +
        value.substring(value.length - 10, value.length)) ||
    "You don't have an Api Key. Please create one.";
  return (
    <>
      <span style={{ fontFamily: "monospace", overflowWrap: "anywhere" }}>
        {hidden ? hiddenValue : value}
      </span>
      <FontAwesomeIcon
        icon={hidden ? faEye : faEyeSlash}
        onClick={() => {
          onClickDisplay();
        }}
        style={{
          cursor: "pointer",
          fontSize: 18,
          marginRight: 15,
          marginLeft: 15,
        }}
      />
      <FontAwesomeIcon
        icon={faCopy}
        onClick={() => {
          onClickColor();
          navigator.clipboard.writeText(value);
        }}
        style={{ color, cursor: "pointer", fontSize: 18 }}
      />
    </>
  );
}

import * as React from "react";

import _ from "lodash";
import constants from "../../api/constants";
import Stripe from "../stripe/Stripe";

export default function SignupStep3({ contract, disabled, setDisabled }) {
  const [price, setPrice] = React.useState();

  React.useEffect(() => {
    setDisabled(true);
  }, []);

  const getData = async () => {
    const res = await fetch(constants.API_URL + "/stripe/price/", {
      credentials: "include",
      method: "GET",
    });

    if (res.status === 200) setPrice((await res.json())?.price);
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Stripe contract={contract} onComplete={() => setDisabled(false)} />
    </div>
  );
}

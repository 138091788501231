import * as React from "react";

import {
  Button,
  Input,
  Modal,
  Table,
  Typography,
  message,
  Popconfirm,
} from "antd";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  contractAddWebhookGQL,
  contractRemoveWebhookGQL,
} from "../../graphql/mutations.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faExternalLink, faWarning } from "@fortawesome/free-solid-svg-icons";
import validator from "validator";
import HiddenText from "../elements/HiddenText";

const { Title } = Typography;

export default function DeveloperWebhooks({ contract }) {
  const [url, setUrl] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  let [addWebhook] = useMutation(contractAddWebhookGQL, {
    refetchQueries: ["user"],
    onError: (err) =>
      message.error("Something went wrong \n" + JSON.stringify(err)),
  });

  let [removeWebhook] = useMutation(contractRemoveWebhookGQL, {
    refetchQueries: ["user"],
    onError: (err) =>
      message.error("Something went wrong \n" + JSON.stringify(err)),
  });

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleOk = () => {
    if (!validator.isURL(url, { protocols: ["https", "http"] }))
      return message.error("URL not valid.");

    addWebhook({ variables: { contractId: contract._id, url, description } });

    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteWebhook = (data) => {
    removeWebhook({ variables: { contractId: contract._id, _id: data._id } });
  };

  const webhooks = _.get(contract, "webhooks", []);
  const flightApiEnabled = _.get(contract, "flightApi");

  const columns = [
    {
      title: "URL endpoint",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "CTOT-Webhook-Key header key",
      render: (ele) => <HiddenText value={ele.key} />,
      key: "key",
    },
    {
      title: "Manage",

      key: "description",
      render: (ele) => (
        <Popconfirm
          title="Delete the endpoint"
          description="Are you sure to delete this endpoint?"
          onConfirm={() => deleteWebhook(ele)}
          okText="Yes"
          cancelText="No"
        >
          <FontAwesomeIcon icon={faTrashAlt} style={{ cursor: "pointer" }} />
        </Popconfirm>
        // <Button size="small" type="ghost" onClick={() => deleteWebhook(ele)}>
        //   <FontAwesomeIcon icon={faTrashAlt} />
        // </Button>
      ),
    },
  ];

  if (!flightApiEnabled)
    return (
      <div>
        <FontAwesomeIcon icon={faWarning} /> Your access to Webhooks is
        currently disabled. Contact us at contact@ctot.app to activate your{" "}
        <strong>Flight API</strong> access in order to use Webhooks.
      </div>
    );

  return (
    <>
      <Modal
        title="Add a new endpoint"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add endpoint"
      >
        <Title level={5}>URL endpoint</Title>
        <Input
          value={url}
          onChange={(event) => setUrl(event.target.value)}
          placeholder="https://"
        />
        <br />
        <br />

        <Title level={5}>Description</Title>
        <Input
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          placeholder="Add a description"
        />
      </Modal>
      <div className="">
        <Title level={3}>Webhooks</Title>
        <p>
          Webhooks are here to build custom scenarios where we send a
          notification to your own server when a CTOT changes. Webhooks are not
          necessary if you use the public app. But they are usefull if you want
          to do advanced notifications using your own channels/servers/apps.
        </p>
        <Title level={4}>Manage your endpoints</Title>
        <p>
          Webhook endpoints are URL that we will use to send data to. We will
          send data about the flights you are subscribed to, or in other words,
          flights that you follow. In order to subscribe to a flight, you need
          to use our API.
          <br />
          For example, if a flight has a CTOT change, and you are subscribed to
          this flight via our API, we will send the new flight data to the
          webhook endpoints listed here.
          <br />
          The data will be posted as JSON and the header will contain the listed
          key under "CTOT-Webhook-Key" name.
        </p>

        <Button onClick={showModal}>
          <FontAwesomeIcon icon={faPlusSquare} /> &nbsp; Add a new endpoint
        </Button>

        <br />
        <br />
        <Table dataSource={webhooks} columns={columns} pagination={false} />
      </div>
      <br />
      <br />

      <div>
        <Title level={3}>Zapier</Title>
        <p>
          Zapier is a plug and play service that requires{" "}
          <strong>zero coding</strong>. You can trigger actions based on
          notifications received.
          <br />
          For example, send an SMS or email when a CTOT changes or track flight
          data in an excel spreadsheet.
        </p>
        <FontAwesomeIcon icon={faWarning} /> You will only need your API key
        that is just below here.
        <br />
        <br />
        <a
          href="https://zapier.com/developer/public-invite/184988/b81c56b5e01f2ea47e9f4b5c9a87a0a9/"
          target="blank"
        >
          <FontAwesomeIcon
            icon={faExternalLink}
            style={{ cursor: "pointer" }}
          />{" "}
          Start building your integration with Zapier
        </a>
        <br /> <br /> <br />
      </div>
    </>
  );
}

import * as React from "react";

import { Typography, Tabs } from "antd";

import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

export default function Support({ contract }) {
  const items = [
    {
      key: "1",
      label: "By email",
      children: (
        <div>
          Open a support discussion by sending an email to:{" "}
          <Link to="mailto:support@ctot.app">support@ctot.app</Link>
        </div>
      ),
    },
    {
      key: "2",
      label: "By Whatsapp",
      children: (
        <div>
          For urgent or more informal support, open a Whatsapp discussion:{" "}
          <br/> <a
            href="https://api.whatsapp.com/send?phone=0033663607366"
            className="float"
            target="_blank"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 5
            }}
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faWhatsapp} size="2x" color="#25D366" />

            <strong style={{ color: "#25D366", marginLeft: 10 }}>
              Start a chat
            </strong>
          </a>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        We are here to help. Please choose one channel below to contact us and
        start support:
      </div>
      <br />
      <Tabs
        defaultActiveKey="1"
        items={items}
        tabBarStyle={{ fontWeight: 600 }}
      />
    </>
  );
}

import * as React from "react";

import _ from "lodash";
import StripePortalLink from "./StripePortalLink";

export default function Billing({ contract }) {
  return (
    <div>
      Click on the following button to open the billing portal. You can edit
      your billing information and payment methods from this portal.
      <br />
      <br />
      We use Stripe, one of the sharpest tool for billing and
      keeping your sensitive payment data secure.
      <br />
      <br />
      For other questions, don't hesitate to contact us at contact@ctot.app
      <br />
      <br />
      <StripePortalLink contract={contract} />
    </div>
  );
}

import * as React from "react";

import _ from "lodash";

export default function Applications({ contract }) {
  const [ios, setIos] = React.useState();

  const getData = async () => {
    const res = await fetch(
      "https://itunes.apple.com/search?term=ctot%2Eapp&country=us&entity=software"
    );

    console.log(res);
  };

  React.useEffect(() => {
    getData();
  });

  return (
    <>
      <div>
        Find the latest versions of the application here:
        <br />
        <br />
      </div>
      <br />
    </>
  );
}

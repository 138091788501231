import React from "react";
import { ConfigProvider, theme } from "antd";
import { BrowserRouter } from "react-router-dom";

import _ from "lodash";
import apolloClient from "./graphql/client";
import { ApolloProvider } from "@apollo/client";
import AppAuth from "./components/AppAuth";

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#423BAB",
          borderRadius: 3,

          // Alias Token
          //colorBgContainer: "grey",

          fontFamily:
            "'IBM Plex Sans', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        },
        components: {
          Button: {
            primaryShadow: "1px 2px 4px rgba(0, 0, 0, 0.02)",
          },
          Card: {
            colorBgContainer: "#fbfbfb",
            boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.02)",
          },
        },
      }}
    >
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <AppAuth />
        </BrowserRouter>
      </ApolloProvider>
    </ConfigProvider>
  );
};
export default App;

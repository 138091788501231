import React, { useState } from "react";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faBuilding, faCreditCard } from "@fortawesome/free-regular-svg-icons";
import {
  faAmbulance,
  faCode,
  faPowerOff,
  faSuitcaseMedical,
  faUnlockKeyhole,
} from "@fortawesome/free-solid-svg-icons";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useMatches,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import Users from "./users/Users";
import { useQuery } from "@apollo/client";
import { userWithContractsGQL } from "../graphql/queries";

import _ from "lodash";
import Certificates from "./certificates/Certificates";
import Developer from "./developer/Developer";
import Billing from "./billing/Billing";
import Organisation from "./organisation/Organisation";
import Applications from "./applications/Applications";
import Subscription from "./subscription/Subscription";
import Logout from "./Logout";
import Contract from "./Contract";
import Support from "./support/Support";
import Signup from "./signup/Signup";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children, link) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  /* getItem(
    "Subscription",
    "/subscription",
    <FontAwesomeIcon icon={faSignature} />
  ), */
  getItem(
    "Certificates",
    "/certificates",
    <FontAwesomeIcon icon={faUnlockKeyhole} />
  ),
  getItem("Users", "/users", <FontAwesomeIcon icon={faUsers} />),
  //getItem("Apps", "/applications", <FontAwesomeIcon icon={faMobilePhone} />),
  getItem(
    "Developers",
    "/developer/api",
    <FontAwesomeIcon icon={faCode} /> /* [
    getItem("API", "/developer/api"),
    getItem("APPS", "/developer/apps"),
  ] */
  ),
  getItem("Billing", "/billing", <FontAwesomeIcon icon={faCreditCard} />),
  getItem(
    "Organisation",
    "/organisation",
    <FontAwesomeIcon icon={faBuilding} />
  ),
  getItem("Support", "/support", <FontAwesomeIcon icon={faSuitcaseMedical} />),
  getItem(<Logout />, "/logout", <FontAwesomeIcon icon={faPowerOff} />),
];

export default function AppContent() {
  return (
    <Routes>
      <Route path="/:contractId/*" element={<AppContentContract />} />
    </Routes>
  );
}

const AppContentContract = () => {
  const { contractId } = useParams();

  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  let { loading, data } = useQuery(userWithContractsGQL);

  const contracts = _.get(data.user, "contracts", []);
  const contract = _.find(contracts, { _id: contractId });

  const requiresContract = _.get(contract, "requiresContract", false);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  if (requiresContract && _.get(pathname.split("/"), "[2]") !== "signup") {
    //console.log("Navigating to signup");
    return <Navigate to="./signup" replace={true} />;
  }

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "2em",
            color: "white",
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          <img
            src="/logo/vector/isolated-monochrome-white.svg"
            style={{ height: "4em", textAlign: "center" }}
          />
          <div
            style={{
              fontSize: collapsed ? 0 : 20,
              marginTop: 20,
              textTransform: "uppercase",
              fontWeight: 700,
              letterSpacing: 1.02,
            }}
          >
            CTOT Account
          </div>

          <div
            style={{
              fontSize: collapsed ? 0 : 10,
              marginTop: 10,
              textTransform: "uppercase",
              fontWeight: 700,
              letterSpacing: 1.02,
            }}
          >
            {contract?.displayName}
          </div>
        </div>

        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
          onSelect={({ key }) => navigate("/" + contractId + key)}
          disabled={requiresContract}
        />
      </Sider>
      <Layout>
        {/* <Header
  style={{
    padding: 0,
    background: colorBgContainer,
  }}
/> */}
        <Content
          style={{
            margin: "0 16px",
          }}
        >
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            <Breadcrumb.Item>{contract?.displayName}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Routes>
                <Route path="/" element={"Main"} />
                <Route path="/users" element={"Users"} />
                <Route path="/certificates" element={"Certificates"} />
                <Route path="/developer/*" element={"Developers"} />
                <Route path="/billing" element={"Billing"} />
                <Route path="/organisation" element={"Organisation"} />
                <Route path="/support" element={"Support"} />
                <Route path="/signup" element={"Activation"} />
              </Routes>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              padding: 24,
              //minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <PageRoutes contract={contract} />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          CTOT.app - Created with ❤️ in Nantes, France
        </Footer>
      </Layout>
    </Layout>
  );
};

function PageRoutes({ contract }) {
  return (
    <Routes>
      <Route path="/" element={<Contract contract={contract} />} />
      <Route path="/users" element={<Users contract={contract} />} />
      <Route
        path="/subscription"
        element={<Subscription contract={contract} />}
      />
      <Route
        path="/certificates"
        element={<Certificates contract={contract} />}
      />
      <Route
        path="/developer/api"
        element={<Developer contract={contract} />}
      />
      <Route
        path="/applications"
        element={<Applications contract={contract} />}
      />
      <Route path="/billing" element={<Billing contract={contract} />} />
      <Route path="/support" element={<Support contract={contract} />} />
      <Route
        path="/organisation"
        element={<Organisation contract={contract} />}
      />
      <Route path="/signup" element={<Signup contract={contract} />} />
    </Routes>
  );
}

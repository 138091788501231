import { gql } from "@apollo/client";

export const logoutGQL = gql`
  mutation logout {
    logout
  }
`;

export const requestMagicLinkGQL = gql`
  mutation loginRequest(
    $email: String!
    $callbackUri: String
    $platform: String
  ) {
    loginRequest(
      email: $email
      callbackUri: $callbackUri
      platform: $platform
    ) {
      type
    }
  }
`;

export const stripeCancelSubscriptionGQL = gql`
  mutation stripeCancelSubscription($subscriptionId: String!) {
    stripeCancelSubscription(subscriptionId: $subscriptionId)
  }
`;

export const contractUpdateUsersGQL = gql`
  mutation contractUpdateUsers($contractId: String!, $users: [JSONObject!], $mode: String) {
    contractUpdateUsers(contractId: $contractId, users: $users, mode: $mode) {
      _id
      domains
      users
      isActive
      displayName
      keys {
        key
        createdAt
      }
    }
  }
`;

export const contractCreateApiKeyGQL = gql`
  mutation contractCreateApiKey($contractId: String!) {
    contractCreateApiKey(contractId: $contractId) {
      _id
      domains
      users
      isActive
      displayName
      keys {
        key
        createdAt
      }
    }
  }
`;

export const contractDeleteApiKeyGQL = gql`
  mutation contractDeleteApiKey($contractId: String!, $key: String!) {
    contractDeleteApiKey(contractId: $contractId, key: $key) {
      _id
      domains
      users
      isActive
      displayName
      keys {
        key
        createdAt
      }
    }
  }
`;

export const contractAddWebhookGQL = gql`
  mutation contractAddWebhook(
    $contractId: String!
    $url: String!
    $description: String
  ) {
    contractAddWebhook(
      contractId: $contractId
      url: $url
      description: $description
    ) {
      _id
      domains
      users
      isActive
      displayName
      apiKey
      webhooks {
        _id
        url
        key
        description
        createdAt
      }
    }
  }
`;

export const contractRemoveWebhookGQL = gql`
  mutation contractRemoveWebhook($contractId: String!, $_id: String!) {
    contractRemoveWebhook(contractId: $contractId, _id: $_id) {
      _id
      domains
      users
      isActive
      displayName
      apiKey
      webhooks {
        _id
        url
        key
        description
        createdAt
      }
    }
  }
`;

export const contractActivateGQL = gql`
  mutation contractActivate($contractId: String!) {
    contractActivate(contractId: $contractId) {
      _id
      requiresContract
    }
  }
`;

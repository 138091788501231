import * as React from "react";

import { Typography, Tabs } from "antd";

import _ from "lodash";
import ReplaceUsers from "./ReplaceUsers";
import ActiveUsers from "./ActiveUsers";
import AddUsers from "./AddUsers";

export default function Users({ contract }) {
  const items = [
    {
      key: "1",
      label: "Active users",
      children: (
        <div className="box">
          <ActiveUsers contract={contract} />
        </div>
      ),
    },
    {
      key: "2",
      label: "Add users",
      children: (
        <div className="box">
          <AddUsers contract={contract} />
        </div>
      ),
    },
    {
      key: "3",
      label: "Update entire user list",
      children: (
        <div className="box danger">
          <ReplaceUsers contract={contract} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        This section contains the users having access to CTOT app. You can
        add/remove/update users from your user list. You can do it as many times
        as you want.
        <br />
        <br />
        Each user in the "Active users" list has access to the CTOT app.
        <br />
        <br />
        You can only add users having an email belonging to your registered
        domain name(s), i.e. emails from the following domains{" "}
        {_.chain(contract)
          .get("domainsAllowed")
          .filter((ele) => ele !== "ctot.app")
          .map((ele, i) => (
            <Typography.Text key={i} type="secondary">
              {ele}
            </Typography.Text>
          ))
          .value()}
      </div>
      <br />
      <Tabs
        defaultActiveKey="1"
        items={items}
        tabBarStyle={{ fontWeight: 600 }}
      />
    </>
  );
}

import * as React from "react";

import _ from "lodash";
import validator from "validator";

import { Button, Input } from "antd";
import { useMutation } from "@apollo/client";
import apolloClient from "../../graphql/client";
import { contractUpdateUsersGQL } from "../../graphql/mutations";

export default function ReplaceUsers({ contract }) {
  const [newUsers, setNewUsers] = React.useState("");

  const domainsAllowed1 = _.get(contract, "domainsAllowed[0]", "example.com");

  React.useEffect(() => {
    //setNewUsers(_.map(contract?.users, "email").join("\n"));
  }, [contract]);

  // Use a mutation to perform the update
  const [addUsersToContract, { data, loading, error }] = useMutation(
    contractUpdateUsersGQL
  );

  const handleAddUsers = (e) => {
    e.preventDefault();

    if (
      !window.confirm(
        "Are you sure you want to replace your existing user list completely?"
      )
    )
      return;

    let newUsersArray = newUsers.split("\n");

    // remove whitespace
    newUsersArray = newUsersArray.map((user) => user.trim());
    newUsersArray = newUsersArray.filter((user) => user.length > 0);

    let newUsersArrayFiltered = _.filter(newUsersArray, (email) => {
      return validator.isEmail(email);
    });

    if (newUsersArrayFiltered.length !== newUsersArray.length) {
      // Display the difference between the 2 arrays
      alert(
        "Check you input, some emails are not valid: \n" +
          newUsersArray
            .filter((email) => !newUsersArrayFiltered.includes(email))
            .join()
      );
      return;
    }

    newUsersArrayFiltered = _.map(newUsersArrayFiltered, (ele) => ({
      email: ele,
    }));

    addUsersToContract({
      variables: {
        contractId: contract._id,
        users: newUsersArrayFiltered,
      },
      refetchQueries: ["user"],
    });
  };

  return (
    <div>
      ⚠️ This will completely replace your user list by the user list inserted
      in this form.
      <br />
      <br />
      Add all emails separated by a new line, for example:
      <br />
      <div style={{ fontFamily: "monospace" }} className="box small">
        user1@{domainsAllowed1}
        <br />
        user2@{domainsAllowed1}
      </div>
      The best way is to simply copy/paste the list of emails from a
      spreadsheet.
      <br />
      <br />
      <Input.TextArea
        rows={20}
        value={newUsers}
        onChange={(e) => setNewUsers(e.target.value)}
      />
      <br />
      <br />
      <Button
        type="primary"
        danger
        onClick={handleAddUsers}
        loading={loading}
        block
      >
        DANGER ZONE : Replace user list entirely by this list
      </Button>
    </div>
  );
}

import * as React from "react";
import { userWithContractsGQL } from "../graphql/queries";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import AppContent from "./AppContent";
import { useLocation, useNavigate } from "react-router-dom";
import LoginPage from "./LoginPage";
import useLoginLink from "./useLoginLink";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function AppAuth() {
  const navigate = useNavigate();
  const location = useLocation();

  useLoginLink();

  let { loading, data } = useQuery(userWithContractsGQL, {
    errorPolicy: "ignore",
  });

  const user = _.get(data, "user", {});
  const isLogged = !loading && user.email;

  const contracts = _.get(user, "contracts", []);

  const hasContract = !loading && contracts?.length > 0;

  if (loading)
    return (
      <div
        style={{
          height: "80vh",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin indicator={<LoadingOutlined spin />} size="large" />
      </div>
    );

  // Redirect to the first contract
  if (!loading && isLogged && hasContract) {
    const firstId = _.first(contracts)._id;
    if (!_.includes(location.pathname, firstId)) {
      //console.log("REDIRECT");
      navigate("/" + _.first(contracts)._id);
    } //else console.log("CORRECT PAGE", location.pathname, firstId);
    //console.log("User logged");
    return <AppContent contractId={firstId} />;
  } else {
    //console.log("User not logged");
    //return null;
    return <LoginPage />;
  }
}

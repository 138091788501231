import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import constants from "../../api/constants";
import { useLocation } from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51IFiUGD0l1OAQaoa2A4WOUcmVUDmVilvEduZMbap9UPIKEKPpYnGSYKV3I6l382dMFhGW2wUbLGZVFYjWdrVXM4x00D0HXWkON"
  //"pk_test_51IFiUGD0l1OAQaoah8l5ylk2KMGIcKAM1KNXLJffX4z3Ej6Ft9TUmw8rziRrVB3hX5pISFDaIZXAYbxMguNPp0Hq00ulxCEZrt"
);

export default function Stripe({ contract, onComplete }) {
  const { pathname } = useLocation();

  const fetchClientSecret = React.useCallback(async () => {
    // Create a Checkout Session
    const res = await fetch(
      constants.API_URL + "/stripe/create-checkout-session-contract",
      {
        method: "POST",
        credentials: "include",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          contractId: contract._id,
          pathname,
        }),
      }
    );

    const data = await res.json();

    return data.client_secret;
  }, []);

  const options = {
    fetchClientSecret,
    onComplete: () =>
      onComplete && onComplete() && console.log("Stripe setup completed"),
  };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}

import * as React from "react";

import _ from "lodash";
import constants from "../../api/constants";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

export default function SignupStep2({ contract, setCurrent }) {
  const [price, setPrice] = React.useState();

  const currency = _.get(price, "currency");

  //const requiresStripe = contract?.requiresStripe;

  const getData = async () => {
    const res = await fetch(constants.API_URL + "/stripe/price/", {
      credentials: "include",
      method: "GET",
    });

    if (res.status === 200) setPrice((await res.json())?.price);
  };

  const tiersUpgraded = _.chain(price)
    .get("tiers", [])
    .map((tier, i, tiers) => {
      let from = 1;
      if (i > 0) from = tiers[i - 1].up_to + 1;
      if (i === tiers.length - 1) tier.up_to = undefined;
      return { from, ...tier };
    })
    .value();

  const columns = [
    {
      title: "User position",
      render: (ele) =>
        `${_.get(ele, "from", 0)} ${
          _.get(ele, "up_to") ? " to " + _.get(ele, "up_to") : "and more"
        }`,
      key: "userIndex",
    },
    {
      title: "Price per user/month",
      render: (ele) => _.get(ele, "unit_amount", 0) / 100 + " " + currency,
      key: "userIndex",
    },
  ];

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      Please take a moment to read our{" "}
      <Link to={"https://www.ctot.app/terms-conditions#top"} target="_blank">
        Terms and Conditions
      </Link>{" "}
      as well as our{" "}
      <Link to={"https://www.ctot.app/privacy-policy#top"} target="_blank">
        Privacy policy
      </Link>
      <div style={{ marginTop: 30 }}>
        Your pricing will be as per the following table. We use a graduated
        pricing, the first 50 users will cost you 1.79 euros/month/user and the
        51th user will cost you 1.59 euros/month etc.
        <br />
        <br />
        * Please note that a minimum bill is set for 20 users.
        <br />
        <br />
        <Table
          columns={columns}
          dataSource={tiersUpgraded}
          rowKey={"up_to"}
          pagination={false}
          //loading={!Boolean(price)}
          loading={!price && { indicator: <LoadingOutlined spin /> }}
        />
      </div>
    </div>
  );
}

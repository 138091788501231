import * as React from "react";

import { Typography, Tabs } from "antd";

import _ from "lodash";

export default function Contract({ contract }) {
  return (
    <>
      <div>
        Welcome to the Account Console.
        <br />
        <br />
        You can manage your account from this page by choosing a topic on the
        left menu.
        <br />
        <br />
      </div>
      <br />
    </>
  );
}

import { Form, Input, Layout, Space, theme } from "antd";
import Login from "./Login";
import { Content, Footer } from "antd/es/layout/layout";

import { ReactComponent as Logo } from "../logo/vector/isolated-monochrome-black.svg";

export default function LoginPage() {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Content
        style={{
          margin: "0 16px",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 24,
            //minHeight: "40ch",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignItems: "center",

            boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.03)",
            border: "1px solid #f0f0f0",
            borderRadius: "3px 3px 0 0",
          }}
        >
          <Logo height={"5em"} />
          <br />
          <div
            style={{
              fontSize: 20,
              textTransform: "uppercase",
              fontWeight: 700,
              letterSpacing: "0.02rem",
            }}
          >
            CTOT <span style={{ fontWeight: 300 }}>Account</span>
          </div>
          <br />
          <br />
          <br />
          <Login />
        </div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        CTOT.app - Created with ❤️ in Nantes, France
      </Footer>
    </Layout>
  );
}

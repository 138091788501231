import * as React from "react";

import {
  Typography,
  Tabs,
  Card,
  Descriptions,
  Badge,
  Button,
  Table,
} from "antd";

import moment from "moment";

import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import {
  contractCreateApiKeyGQL,
  contractDeleteApiKeyGQL,
} from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCopy, faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

export default function DeveloperUsersAPI({ contract }) {
  return (
    <>
      <div>
        Our <strong>Users API</strong> is here to automate your user management.
        This enables your organisation to automate the update of your user list.{" "}
        <strong>Users API</strong> is <strong>free</strong> and available to each organisation.
        <br />
        <br />
        For example, you can take the list of all your pilots and push it into
        our server every night, so that you don't have to manually update your
        user list.
        <br />
        <br />
        You can find our API documentation here:{" "}
        <Link
          to={"https://www.ctot.app/docs/business-api-users"}
          target="_blank"
        >
          https://www.ctot.app/docs/business-api-users
        </Link>
      </div>
      <br />
    </>
  );
}

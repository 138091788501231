import * as React from "react";

import { Typography } from "antd";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faWarning } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const { Title } = Typography;

export default function DeveloperZapier({ contract }) {
  const flightApiEnabled = _.get(contract, "flightApi");

  if (!flightApiEnabled)
    return (
      <div>
        Zapier is a plug and play service that requires{" "}
        <strong>zero coding</strong>. You can trigger actions based on
        notifications received.
        <br />
        For example, send an SMS or email when a CTOT changes or track flight
        data in an excel spreadsheet. Find out more about Zapier on their
        website:{" "}
        <Link to="https://zapier.com" target="_blank">
          www.zapier.com
        </Link>
        <br /> <br />
        <FontAwesomeIcon icon={faWarning} /> Your access is currently disabled.
        Contact us at contact@ctot.app to activate your{" "}
        <strong>Flight API</strong> access in order to use Zapier.
      </div>
    );

  return (
    <div>
      Zapier is a plug and play service that requires{" "}
      <strong>zero coding</strong>. You can trigger actions based on
      notifications received.
      <br />
      For example, send an SMS or email when a CTOT changes or track flight data
      in an excel spreadsheet.
      <FontAwesomeIcon icon={faWarning} /> You will only need your API key that
      is just below here.
      <br />
      <br />
      <Link
        to="https://zapier.com/developer/public-invite/184988/b81c56b5e01f2ea47e9f4b5c9a87a0a9/"
        target="_blank"
      >
        <FontAwesomeIcon icon={faExternalLink} style={{ cursor: "pointer" }} />{" "}
        Start building your integration with Zapier
      </Link>
      <br /> <br /> <br />
    </div>
  );
}
